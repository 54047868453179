<template>
  <router-view></router-view>

  <audio class="audio"
         src="mp3/success.mp3"
         controls
         id="successAudio"
         hidden="true">
  </audio>

  <audio class="audio"
         src="mp3/warning.mp3"
         controls
         id="warningAudio"
         hidden="true">
  </audio>


</template>

<script>

  export default {
    mounted() {
      // console.log(this.$websocket,'app.vue')
      // // 获取元素标签
      let successAudioDom = document.getElementById('successAudio')
      // 触发交互
      successAudioDom.pause()
      // 获取元素标签
      let warningAudioDom = document.getElementById('warningAudio')
      // 触发交互
      warningAudioDom.pause()
    },
  }
</script>

<style>

html,
body,
#app {
  width: 100%;
  height: 100%;
}

/*修复input选中没有蓝色的bug*/
.el-input__inner::selection {
  background-color: #318efe !important;
  color: #fff !important;
}

</style>
