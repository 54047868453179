import {defineStore} from "pinia";
import storage from "@/utils/storage";
import router from "@/router";
import dynamic from "@/router/openTreeToArray";
import request from "@/request";
import tabsStore from "@/store/modules/tabs";


/**
 * @description: 用于用户数据
 */
const userStore = defineStore("user", {
    state: () => ({
        /**
         * token
         */
        token: storage.get("token"),
        /**
         * 用户信息
         */
        info: {},
        /**
         * 用户拥有的角色
         */
        roles: [],
        /**
         * 用户拥有的权限
         */
        permissions: [],
        /**
         * 初始化路由标记
         */
        initRouter: false,
        /**
         * 路由
         */
        router: [],
    }),
    actions: {
        /**
         * @description: 保存token
         * @param token
         */
        saveToken(token) {
            storage.set("token", token);
            this.token = token;
        },
        /**
         * @description: 保存用户信息 角色以及权限数据
         * @param data
         */
        saveUserInfoRolesPermissions(data) {
            this.info = data.user;
            this.roles = data.roles;
            this.permissions = data.permissions;
        },
        /**
         * @description: 保存路由
         * @param data
         */
        saveRouter(data) {
            this.router = data;
            // 动态配置路由
            dynamic.appendDynamicRoutes(data);
        },
        /**
         * @description: 退出登录
         */
        logout() {
            storage.clearAll();
            this.$reset();
            tabsStore().$reset();
            router.push({path: "/login", replace: true})
        },
        /**
         * @description: 获取用户信息
         * @returns {Promise<void>}
         */
        async getMenu() {
            let res = await request.get("menu");
            userStore().saveRouter(res.data);
        }
    }

})


export default userStore
