<template>
  <div>
    <el-dropdown trigger="click">
    <span class="el-dropdown-link">
      {{ userStore().info.username }}
      <el-icon class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>
          <el-dropdown-item @click="userStore().logout">安全退出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import userStore from "@/store/modules/user";
import {ArrowDown} from '@element-plus/icons-vue'
import {useRouter} from "vue-router";

const router = useRouter();
const changePassword = () => {
  router.push('/change/password')
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  height: 60px;
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;

}
</style>